import {FileContent, httpService} from "@/services/HttpService";
import {FederationRef} from "@/modules/federation/services/MyFederationService";
import {GeoLocation} from "@/shared/domain/Address";
import {OrganisationUserRole, OrganisationUserStatute} from "@/shared/domain/User";
import {RefWithName} from "@/utils/Utils";
import Vue from "vue";
import {SportTypeVariant, SportTypeVariantRef} from "@/modules/federation/services/SportTypeVariantService";
import {
    MemberLicense,
    MemberRef,
    Membership,
    MembershipAssociationType,
    MembershipStatute,
    OrganisationSummary,
    PersonRelation
} from "@/modules/members/services/MemberService";
import {CompetitionDetail, CompetitionListItem, CompetitionRef} from "@/modules/competition/service/CompetitionService";
import {DateUtils} from "@/utils/DateUtils";
import {CompetitionTestRef, SportCategory} from "@/modules/competition/service/CompetitionTestsService";
import {CareerRef} from "@/modules/federation/services/CareerService";
import {RegistrationStatus} from "@/modules/competition/service/ManageSubscriptionService";
import {Gender, PersonRef} from "@/shared/domain/Person";
import {Language} from "@/shared/domain/General";
import {Practitioner} from "@/shared/domain/Practitioner";
import {SportCategoryRef} from "@/modules/federation/services/SportCategoryService";
import {PersonName} from "@/shared/domain/CompetitionPractitioner";
import {
    EqifyEvaluationResponse,
    PractitionerEvaluation,
    PractitionerEvaluations
} from "@/services/RegistrationRulesService";
import {OrganisationTeamResponse} from "@/shared/domain/Combination";
import {CustomAttribute} from "@/shared/domain/CustomAttributes";
import {MasterlistItem} from "@/modules/competition/service/PublicCompetitionMasterlistService";
import {MemberBenefitAssociation} from "@/shared/MemberBenefit";
import {AccountResource} from "@/shared/domain/Account";

class OrganisationService {
    createUploadAvatarUrl(ref: OrganisationRef): string {
        return `/organisation/${ref}/avatar`
    }

    createOrganisation(formData: SaveOrganisationForm, federationRef: string): Promise<OrganisationRef> {
        return OrganisationService.saveOrganisation(`/organisation`, formData, federationRef);
    }

    updateOrganisation(organisationRef: OrganisationRef, formData: SaveOrganisationForm, federationRef: string) {
        return OrganisationService.saveOrganisation(`/organisation/${organisationRef}`, formData, federationRef);
    }

    getOrganisationProfile(ref: OrganisationRef): Promise<OrganisationProfile> {
        return httpService.get(`/organisation/${ref}/profile`)
    }

    getInitialSaveOrganisationFormDataForCreate(federationRef: string): Promise<SaveOrganisationFormMetaData> {
        return this.getInitialSaveFormData(federationRef).then((metaData: SaveOrganisationFormMetaData) => {
            return Promise.resolve(
                metaData
            )
        })
    }

    getInitialSaveOrganisationFormDataForUpdate(organisationRef: OrganisationRef | undefined = undefined): Promise<SaveOrganisationFormMetaData> {
        return httpService.get(`/organisation/${organisationRef}`).then((data: any) => {
            const form: SaveOrganisationForm = {
                name: data.name,
                site: data.site,
                federation: data.federation,
                motherOrganisation: data.motherOrganisation ? {
                    ref: data.motherOrganisation.ref,
                    name: data.motherOrganisation.name
                } : {name: "", ref: ""},
                externalId: data.externalId,
                organisationType: data.organisationType,
                organisationLevel: data.organisationLevel,
                sportTypeVariants: data.sportTypeVariants || [],
                customAttributes: data.customAttributes,
                website: data.website,
                generalInfo: data.generalInfo,
                isHorse: data.isHorse,
                isPony: data.isPony,
                accounts: data.accounts
            };
            return this.getInitialSaveFormData(data.federation).then((metaData: SaveOrganisationFormMetaData) => {
                return Promise.resolve(
                    {
                        ...metaData,
                        form: form
                    }
                );
            });
        });
    }

    private static saveOrganisation(url: string, formData: SaveOrganisationForm, federationRef: string): Promise<OrganisationRef> {
        const submitForm = {
            ...formData,
            motherRef: formData.motherOrganisation && formData.motherOrganisation.ref !== '' ? formData.motherOrganisation.ref : null,
            federation: federationRef,
        };
        return httpService.post(url, submitForm);
    }

    private getInitialSaveFormData(federationRef: string): Promise<SaveOrganisationFormMetaData> {
        return httpService.get(`/organisation/create/meta-data?federation=${federationRef}`).then((data: any) => {
            return Promise.resolve(
                {
                    availableOrganisationTypes: data.organisationTypes,
                    availableOrganisationLevels: data.organisationLevels,
                    availableSportTypes: data.sportTypeVariants,
                    form: initEmptySaveOrganisationForm()
                }
            );
        });
    }

    getOrganisationMembersOverview(ref: OrganisationRef, showInActive: boolean): Promise<OrganisationMembersOverview> {
        return httpService.get(`/organisation/${ref}/members?showInActive=${showInActive}`)
    }

    getCompetitionsOpenForSubscription(ref: OrganisationRef): Promise<OpenCompetitions> {
        return httpService.get(`/organisation/${ref}/competitions/registrations`)
    }

    getHistoricalCompetitions(ref: OrganisationRef, start: Date, end: Date): Promise<Array<CompetitionListItem>> {
        return httpService.get(`/organisation/${ref}/competitions/registrations/history?start=${DateUtils.toDateString(start)}&end=${DateUtils.toDateString(end)}`)
    }

    getCompetitionSubscriptionMetadata(ref: OrganisationRef, competitionRef: CompetitionRef): Promise<OrganisationCompetitionRegistrationMetadata> {
        return httpService.get(`/organisation/${ref}/competitions/registrations/${competitionRef}/metadata`)
    }

    saveCompetitionSubscriptions(ref: OrganisationRef, competitionRef: CompetitionRef, subscriptions: Array<OrganisationRegistrationSaveRequest>, confirmSaveWithWarnings: boolean): Promise<PractitionerEvaluations> {
        return httpService.post(`/organisation/${ref}/competitions/registrations`, {
            competitionRef: competitionRef,
            confirmSaveWithWarnings: confirmSaveWithWarnings,
            subscriptions: subscriptions
        })
    }

    saveCompetitionSubscription(
        ref: OrganisationRef, competitionRef: CompetitionRef, subscription: OrganisationRegistrationSaveRequest, confirmSaveWithWarnings: boolean
    ): Promise<PractitionerEvaluation> {
        return httpService.post(`/organisation/${ref}/competitions/registration`, {
            competitionRef: competitionRef,
            confirmSaveWithWarnings: confirmSaveWithWarnings,
            subscription: subscription
        })
    }

    getOrganisationTeams(ref: OrganisationRef): Promise<OrganisationTeamResponse> {
        return httpService.get(`/organisation/${ref}/competitions/teams`)
    }

    getOrganisationMemberData(ref: OrganisationRef, memberRef: MemberRef): Promise<OrganisationMemberData> {
        return httpService.get(`/organisation/${ref}/members/${memberRef}`).then((data: any) => {
            const form: OrganisationMemberData = {
                ...data,
                birthDate: DateUtils.fromDate(data.birthDate),
            };
            return Promise.resolve(form);
        });
    }

    exportMembers(ref: OrganisationRef, includeInActive: boolean, date?: string): Promise<FileContent> {
        return httpService.download(`/organisation/${ref}/members/export`, {
            params: {
                includeInactive: includeInActive,
                date: date
            }
        })
    }

    private transformOrganisationMemberData(formData: OrganisationMemberData) {
        return {
            ...formData,
            membership: {
                ref: formData.membership!!.ref!!,
                associationType: formData.membership!!.associationType!!,
                organisation: formData.membership!!.organisation!!,
                role: formData.membership!!.role!!,
                statutes: formData.membership!!.statutes!!.map(s => {
                    return {
                        ref: s.ref!!,
                        statute: s.statute!!,
                        since: s.since ? DateUtils.toDateString(s.since!!) : undefined,
                        until: s.until ? DateUtils.toDateString(s.until!!) : undefined,
                        markForDeletion: s.markForDeletion
                    }
                }),
                memberId: formData.membership!!.memberId
            },
            licenses: formData.licenses.map(l => {
                return {
                    ...l,
                    ref: l.ref,
                    markedForDeletion: l.markedForDeletion,
                    start: DateUtils.toDateString(l.start),
                    end: l.end ? DateUtils.toDateString(l.end) : undefined
                }
            }),
            memberBenefits: formData.memberBenefits.map(mb => {
                return {
                    ref: mb.ref,
                    memberBenefitRef: mb.memberBenefit.ref
                }
            }),
            birthDate: DateUtils.toDateString(formData.birthDate)
        };
    }

    saveOrganisationMemberData(organisationRef: OrganisationRef, personRef: PersonRef, formData: OrganisationMemberData) {
        const form = this.transformOrganisationMemberData(formData)
        return httpService.put(`/organisation/${organisationRef}/members/${personRef}`, form)
    }

    createOrganisationMember(organisationRef: OrganisationRef, formData: OrganisationMemberData) {
        const form = this.transformOrganisationMemberData(formData)
        return httpService.post(`/organisation/${organisationRef}/members`, form)
    }

    endOrganisationMemberMembership(organisationRef: OrganisationRef, memberRef: MemberRef, end: Date) {
        const transformedDate = DateUtils.toDateString(end!!)
        return httpService.delete(`/organisation/${organisationRef}/members/${memberRef}?end=${transformedDate}`)
    }

    getOrganisationCompetitions(organisationRef: OrganisationRef): Promise<Array<CompetitionDetail>> {
        return httpService.get(`/organisation/${organisationRef}/masterlist-competitions`)
    }

    getOrganisationCompetitionMasterlist(organisationRef: OrganisationRef, competitionRef: CompetitionRef): Promise<Array<MasterlistItem>> {
        return httpService.get(`/organisation/${organisationRef}/masterlist/${competitionRef}`)
    }
}

export interface OpenCompetitions {
    lateRegistrationCompetitions: Array<OpenCompetition>,
    thisWeekCompetitions: Array<OpenCompetition>,
    nextWeekCompetitions: Array<OpenCompetition>,
    laterCompetitions: Array<OpenCompetition>
}

export type OrganisationRef = string;

export interface SaveOrganisationForm {
    name: string,
    site?: string,
    federation: string,
    motherOrganisation?: RefWithName,
    externalId: string,
    organisationType: string,
    organisationLevel: string,
    sportTypeVariants: Array<SportTypeVariantRef>,
    customAttributes?: Array<CustomAttribute>,
    website?: string,
    generalInfo?: string,
    isHorse: boolean,
    isPony: boolean,
    accounts: Array<AccountResource>
}

export interface SaveOrganisationFormMetaData {
    form: SaveOrganisationForm,
    availableOrganisationTypes: string[],
    availableOrganisationLevels: string[],
    availableSportTypes: SportTypeVariant[]
}


export interface OpenCompetition extends CompetitionListItem {
    subscriptionOpen: String,
    subscriptionClose: String,
    subscriptionAdditional: String
}

export const initEmptySaveOrganisationForm = () => {
    const form: SaveOrganisationForm = {
        name: "",
        federation: "",
        externalId: "",
        motherOrganisation: undefined,
        organisationType: "",
        organisationLevel: "",
        sportTypeVariants: [],
        isHorse: true,
        isPony: true,
        accounts: []
    };
    return form
};

export interface OrganisationProfile {
    ref?: OrganisationRef,
    federationRef?: FederationRef,
    name?: string,
    site?: string,
    avatar?: string,
    organisationType?: string,
    organisationLevel?: string,
    registeredOfficeAddress?: GeoLocation,
    trainingSiteAddress?: GeoLocation,
    sportTypeVariants?: Array<SportTypeVariant>,
    memberCount?: OrganisationMemberCount,
    contacts?: Array<OrganisationContact>,
    iban?: string;
    bic?: string;
    website?: string;
    generalInfo?: string;
    isHorse?: Boolean;
    isPony?: Boolean;
}

export interface OrganisationMemberCount {
    total: number,
    sportTypeVariants: Array<SportTypeMemberCount>
}

export interface SportTypeMemberCount {
    sportTypeVariant: SportTypeVariant;
    total: number;
}

export interface OrganisationContact {
    role: OrganisationUserRole;
    person: PersonName;
}

export interface OrganisationMembersOverview {
    members: Array<OrganisationMemberEntry>
}

export interface OrganisationMemberEntry {
    ref: string,
    member: PersonName,
    firstName?: string,
    lastName?: string,
    avatar: string,
    role: OrganisationUserRole,
    statutes: Array<OrganisationUserStatute>
    active: boolean
}

export interface OrganisationCompetitionRegistrationMetadata {
    competitionRef: CompetitionRef,
    name: String,
    subscriptionOpen: String,
    subscriptionClose: String,
    subscriptionAdditional: String,
    careerTests: Array<CareerTest>,
    tests: Array<RegistrationMetadataTest>,
    registrations: Array<OrganisationRegistration>
}

export interface CareerTest {
    career: RefWithName,
    sportTypeVariant: SportTypeVariant,
    tests: Array<RegistrationMetadataTest>,
}

export interface RegistrationMetadataTest {
    testRef?: CompetitionTestRef,
    name: string,
    sportTypeVariant: SportTypeVariant,
    sportCategory?: SportCategory,
    career?: RefWithName,
    championship?: RefWithName,
    maxRegistrationsPerCombination: number
}

export interface OrganisationRegistration {
    practitioner: Practitioner,
    careers: Array<PractitionerCareerStatus>
    registrations: Array<OrganistionTestRegistration>
}

export interface PractitionerCareerStatus {
    careerRef: CareerRef,
    achievedSportCategory: SportCategoryRef,
    activeSportCategory?: SportCategoryRef,
    suspended: boolean,
    suspendedReason?: string
}

export interface OrganistionTestRegistration {
    registrationRef?: string,
    registered: boolean,
    testRef: CompetitionTestRef,
    sportCategoryRef: SportCategoryRef,
    datetime: string,
    outOfCompetition: boolean,
    quantity: number,
    status: RegistrationStatus,
    evaluation?: EqifyEvaluationResponse
}


export interface OrganisationRegistrationSaveRequest {
    practitionerRef: string,
    confirmSaveWithWarnings: boolean,
    registrations: Array<OrganisationSubscriptionSaveRequest>
}

export interface OrganisationSubscriptionSaveRequest {
    registrationRef?: string,
    registered: boolean,
    testRef: CompetitionTestRef,
    outOfCompetition: boolean
    quantity: number,
    isNew: boolean
}

export interface OrganisationMemberData {
    personRef: PersonRef,
    organisationRef: OrganisationRef,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    birthDate?: Date,
    gender?: Gender,
    address?: GeoLocation,
    organisationRole: OrganisationUserRole;
    organisationStatutes: Array<MembershipStatute>;
    organisationAssociationType: MembershipAssociationType;
    membership?: Membership,
    licenses: MemberLicense[],
    memberBenefits: MemberBenefitAssociation[],
    defaultFederation?: FederationRef
    defaultLanguage?: Language,
    activeUntil?: Date
    activeSince?: Date,
    relations: Array<PersonRelation>
}

export const initEmptyOrganisationMemberData = () => {
    const form: OrganisationMemberData = {
        personRef: "",
        organisationRef: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        organisationRole: OrganisationUserRole.ORGANISATION_MEMBER,
        organisationStatutes: [],
        organisationAssociationType: MembershipAssociationType.OTHER,
        membership: {
            ref: "",
            markForDeletion: false,
            organisation: {} as OrganisationSummary,
            role: OrganisationUserRole.ORGANISATION_MEMBER,
            statutes: [],
            canOrganizeCompetitions: false,
            associationType: MembershipAssociationType.RECREATIONAL,
            valid: false,
            end: DateUtils.now()
        },
        licenses: [],
        memberBenefits: [],
        relations: []
    }
    return form
}

export const initEmptyOrganisationProfile = () => {
    const form: OrganisationProfile = {
        ref: undefined,
        federationRef: undefined,
        name: "",
        avatar: undefined,
        registeredOfficeAddress: undefined,
        trainingSiteAddress: undefined,
        sportTypeVariants: [],
        memberCount: {sportTypeVariants: [], total: 0},
        contacts: []
    };
    return form
};

export const getOrganisationRefFromRoute = (vue: Vue): OrganisationRef => {
    return vue.$route.params.organisation;
};

export const organisationService = new OrganisationService();
