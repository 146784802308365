import {FileContent, httpService} from "@/services/HttpService";
import {CompetitionRef} from "@/modules/competition/service/CompetitionService";
import {
    CompetitionTestRef,
    CompetitionTestSetRef,
    SportCategory
} from "@/modules/competition/service/CompetitionTestsService";
import {NameInput, RefWithName} from "@/utils/Utils";
import {SportTypeVariant} from "@/modules/federation/services/SportTypeVariantService";
import {PractitionerType} from "@/shared/domain/Practitioner";
import {CompetitionPractitioner, HorseName, OrganisationName} from "@/shared/domain/CompetitionPractitioner";
import {PersonRef} from "@/shared/domain/Person";
import {EqifyEvaluationResponse, EqifyRulesResponse} from "@/services/RegistrationRulesService";
import {FinanceTransactionStatus} from "@/shared/domain/Finance";
import {PriceProfile} from "@/shared/domain/General";

class ManageSubscriptionService {
    getData(ref: CompetitionRef): Promise<RegistrationData> {
        return httpService.get(`/competition/manage/${ref}/subscription/metadata`)
    }

    getRegistrations(competition: CompetitionRef, testRef: CompetitionTestRef): Promise<RegistrationResponse> {
        return httpService.get(`/competition/manage/${competition}/subscription/test/${testRef}`)
    }

    save(competition: CompetitionRef, request: RegistrationRequest): Promise<SaveRegistrationResponse> {
        return httpService.post(`/competition/manage/${competition}/subscription/test/${request.testRef}`, request)
    }

    delete(competition: CompetitionRef, testRef: CompetitionTestRef, subscriptionRef: RegistrationRef): Promise<void> {
        return httpService.delete(`/competition/manage/${competition}/subscription/test/${testRef}/${subscriptionRef}`)
    }

    deleteMultiple(competition: CompetitionRef, testRef: CompetitionTestRef, registrations: Array<RegistrationRef>): Promise<void> {
        return httpService.post(`/competition/manage/${competition}/subscription/test/${testRef}/delete`, {registrationRefs: registrations})
    }

    updateStatus(competition: CompetitionRef, testRef: CompetitionTestRef, practitionerRef: string, status: RegistrationStatus): Promise<RegistrationStatus> {
        return httpService.post(`/competition/manage/${competition}/subscription/test/${testRef}/${practitionerRef}/status`, {status: status})
    }

    signOff(competition: CompetitionRef, registrationRef: RegistrationRef, reason: string): Promise<void> {
        return httpService.post(`/competition/manage/${competition}/subscription/${registrationRef}/signoff`, {reason: reason})
    }

    updatePaymentStatus(competition: CompetitionRef, testRef: CompetitionTestRef, practitionerRef: string, status: FinanceTransactionStatus): Promise<FinanceTransactionStatus> {
        return httpService.put(`/competition/manage/${competition}/subscription/test/${testRef}/${practitionerRef}/payment-status?status=${status}`, {})
    }

    getIndividualCombinationMetadataForRiderUseCase(ref: CompetitionRef, testRef: CompetitionTestRef, riderRef: PersonRef): Promise<IndividualCombinationMetadata> {
        return httpService.get(`/competition/manage/${ref}/subscription/test/${testRef}/metadata/individual/${riderRef}`)
    }

    saveRules(ref: CompetitionTestRef, rules: any): Promise<void> {
        return httpService.post(`/competition/manage/${ref}/subscription/rules`, {rules: rules})
    }

    getRules(ref: CompetitionRef): Promise<EqifyRulesResponse> {
        return httpService.get(`/competition/manage/${ref}/subscription/rules`)
    }

    getPossibleTargetsForMove(competition: CompetitionRef, testRef: CompetitionTestRef): Promise<Array<CandidateTestTargetResource>> {
        return httpService.get(`/competition/manage/${competition}/subscription/test/${testRef}/move/targets`)
    }

    getStartlistEntryCandidatesForTestSet(competition: CompetitionRef, testRef: CompetitionTestRef, testSetRef: CompetitionTestSetRef): Promise<Array<CandidateStartlist>> {
        return httpService.get(`/competition/manage/${competition}/subscription/test/${testRef}/startlist-entry-candidates/${testSetRef}`)
    }

    moveRegistrations(competition: CompetitionRef, testRef: CompetitionTestRef, registrations: Array<RegistrationRef>, targetTestSetRef: CompetitionTestSetRef, startPositions: Array<TargetStartlistPosition>): Promise<void> {
        return httpService.post(`/competition/manage/${competition}/subscription/test/${testRef}/move`, {
            targetTestSetRef: targetTestSetRef,
            registrationRefs: registrations,
            startPositions: startPositions
        })
    }

    export(competition: CompetitionRef): Promise<FileContent> {
        return httpService.download(`/competition/manage/${competition}/subscription/export`)
    }
}

export type RegistrationRef = string;

export interface RegistrationData {
    menu: RegistrationsMenu
}

export interface RegistrationsMenu {
    entries: Array<ConfigurationTestMenuEntry>
}

export interface ConfigurationTestMenuEntry {
    ref: CompetitionTestRef,
    name: string,
    registrationCount: number,
    entries: Array<TestMenuEntry>
}

export interface TestMenuEntry {
    ref: CompetitionTestRef
    name: string,
    maxRegistrations: number,
    registrationCount: number,
    includedInCareer: boolean,
    includedInChampionship: boolean,
    cancelled: boolean
}

export interface IndividualCombinationMetadata {
    organisations: Array<OrganisationName>
    horses: Array<HorseName>,
    priceProfiles: Array<PriceProfile>
}

export interface SaveRegistrationResponse {
    registration: Registration,
    evaluationResponse: EqifyEvaluationResponse,
    saved: boolean
}

export interface UpdateRegistrationResponse {
    registration: Registration,
    evaluationResponse: EqifyEvaluationResponse,
    saved: boolean
}

export interface Registration {
    ref: RegistrationRef,
    outOfCompetition: boolean,
    notes?: string,
    order: number,
    datetime: string,
    practitioner: CompetitionPractitioner,
    status: RegistrationStatus,

    //Ui only
    _rowVariant?: string
}

export interface RegistrationResponse {
    ref: CompetitionTestRef
    name: string,
    testSets: Array<RefWithName>,
    sportTypeVariant: SportTypeVariant,
    sportCategory?: SportCategory,
    career?: RefWithName,
    championship?: RefWithName,
    protocol: RefWithName,
    maxRegistrations: number,
    registrationCount: number,
    maxParticipationsPerCombination: number,
    defaultOutOfCompetition: boolean,
    practitionerType: PractitionerType,
    registrations: Array<Registration>
    waitingQueue: Array<Registration>
}

export interface RegistrationRequest {
    ref?: RegistrationRef,
    testRef: CompetitionTestRef,
    testSetRef?: CompetitionTestSetRef,
    quantity: number,
    data: RegistrationPractitionerRequest,
    outOfCompetition: boolean,
    notes?: string,
    startPositions?: Array<TargetStartlistPosition>,
    priceProfileRef?: string,
    confirmSaveWithWarnings: boolean
}

export interface UpdateRegistrationRequest {
    data: RegistrationPractitionerRequest,
    outOfCompetition: boolean,
    notes?: string,
    priceProfileRef?: string,
    confirmSaveWithWarnings: boolean
}

export interface RegistrationPractitionerRequest {
    type: PractitionerType,
    practitionerRef?: string,
    isUnknown: boolean
}

export interface IndividualRegistrationPractitionerRequest extends RegistrationPractitionerRequest {
    person?: NameInput
    organisation?: NameInput
    horse?: NameInput,
    horsePony?: boolean,
    horseWithersHeightCode?: string,
    horseUeln?: string,
    horseFather?: NameInput,
    horseFatherOfMother?: NameInput,
    horseBreeder?: NameInput,
    horseOwner?: NameInput
}

export interface HorseRegistrationPractitionerRequest extends RegistrationPractitionerRequest {
    presenter?: NameInput
    organisation?: NameInput
    horse?: NameInput,
    horsePony?: boolean,
    horseWithersHeightCode?: string,
    horseUeln?: string,
    horseFather?: NameInput,
    horseFatherOfMother?: NameInput,
    horseBreeder?: NameInput,
    horseOwner?: NameInput
}

export interface TeamRegistrationPractitionerRequest extends RegistrationPractitionerRequest {
    teamRef: string
}

export enum RegistrationStatus {
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    SUBSCRIBED = 'SUBSCRIBED',
    REJECTED = 'REJECTED',
    SIGNED_OFF = "SIGNED_OFF",
    ANNOUNCED = "ANNOUNCED",
    CANCELLED = "CANCELLED"
}

export interface RegistrationValidationResult {
    isValid: boolean,
    messages: Array<RegistrationValidationMessage>
}

export interface RegistrationValidationMessage {
    ruleRef: string,
    message: string,
    errorLevel: ValidationErrorLevel
}

export enum ValidationErrorLevel {
    INFO = "INFO",
    WARNING = "WARNING",
    ERROR = "ERROR",
    FATAL = "FATAL"
}

export interface CandidateTestTargetResource {
    ref: string,
    name: string,
    testSetName: string,
    hasMultipleTestSets: boolean,
    sportTypeVariant: SportTypeVariant,
    sportCategory?: SportCategory,
    appliesForCareer: boolean,
    appliesForChampionship: boolean
}

export interface CandidateStartlist {
    ref: string,
    name: string,
    set: RefWithName,
    entries: Array<CandidateStartlistEntry>
}

export interface CandidateStartlistEntry {
    ref?: string,
    name?: string,
    position: number,
    time?: string,
    type: CandidateStartlistEntryType
}

export enum CandidateStartlistEntryType {
    END = "END",
    OPEN = "OPEN"
}

export interface TargetStartlistPosition {
    startlistRef: string,
    positionRef: string
}

export const manageSubscriptionService = new ManageSubscriptionService();
