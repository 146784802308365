import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {NameInput} from "@/utils/Utils";
import {
    HorseRegistrationPractitionerRequest,
    RegistrationRequest,
    TargetStartlistPosition,
    UpdateRegistrationRequest
} from "@/modules/competition/service/ManageSubscriptionService";
import {PractitionerType} from "@/shared/domain/Practitioner";
import {
    GetHorseOnlyFormMetadataRequest,
    GetHorseOnlyRegistrationOfferRequest
} from "@/modules/competition/service/ManageRegistrationService";
import {RegistrationHorseData} from "@/modules/competition/store/registration/common";
import {DateUtils} from "@/utils/DateUtils";

@Module({namespaced: true, name: 'competition-horse-registration-module'})
export default class CompetitionHorseRegistrationModule extends VuexModule {
    registrationRef: string | null = null
    practitionerRef: string | null = null

    presenter: NameInput | null = null
    horse: RegistrationHorseData | null = null
    organisation: NameInput | null = null

    priceProfile: string = ""
    suggestedPriceProfile: string = ""

    notes: string = ""
    quantity: number = 1
    outOfCompetition: boolean = false
    forSale: boolean = false

    changedForm: boolean = false
    isUnknown: boolean = false

    @Mutation
    reset() {
        this.registrationRef = null
        this.practitionerRef = null
        this.presenter = null
        this.horse = null
        this.organisation = null
        this.priceProfile = ""
        this.notes = ""
        this.suggestedPriceProfile = ""
        this.quantity = 1

        this.isUnknown = false
        this.changedForm = false
    }

    @Mutation
    initialize(data: {
        ref: string | null,
        practitionerRef: string | null,
        presenter: NameInput | null,
        horse: RegistrationHorseData | null,
        organisation: NameInput | null
        notes: string | undefined,
        outOfCompetition: boolean
        forSale: boolean
    }) {
        this.registrationRef = data.ref
        this.practitionerRef = data.practitionerRef
        this.presenter = data.presenter
        this.horse = data.horse
        this.organisation = data.organisation
        this.notes = data.notes || ""
        this.outOfCompetition = data.outOfCompetition
        this.forSale = data.forSale

        this.isUnknown = data.horse?.ref == undefined
    }

    @Mutation
    changeForm() {
        this.isUnknown = !this.isUnknown
        this.horse = null
        this.presenter = null
        this.organisation = null

        this.changedForm = true
    }

    @Mutation
    updateIsUnknown(value: boolean) {
        this.isUnknown = value
    }

    @Mutation
    updatePresenter(presenter: NameInput | null) {
        this.presenter = presenter
    }

    @Mutation
    updateHorse(horse: RegistrationHorseData | null) {
        this.horse = horse
    }

    @Mutation
    updateOrganisation(organisation: NameInput | null) {
        this.organisation = organisation
    }

    @Mutation
    updatePriceProfile(priceProfile: string) {
        this.priceProfile = priceProfile
    }

    @Mutation
    updateNotes(notes: string) {
        this.notes = notes
    }

    @Mutation
    updateSuggestedPriceProfileRef(ref: string) {
        this.suggestedPriceProfile = ref
    }

    @Mutation
    updateQuantity(quantity: number) {
        this.quantity = quantity
    }

    @Mutation
    updateOutOfCompetition(value: boolean) {
        this.outOfCompetition = value
    }

    @Mutation
    updateForSale(value: boolean) {
        this.forSale = value
    }

    @Action
    asPractitioner(): HorseRegistrationPractitionerRequest {
        return {
            type: PractitionerType.HORSE,
            practitionerRef: this.practitionerRef,
            isUnknown: this.isUnknown,
            presenter: this.presenter,
            organisation: this.organisation,
            horse: this.horse,
            birthDate: this.horse?.birthDate ? DateUtils.toDateString(DateUtils.fromDate(this.horse?.birthDate)) : null,
            gender: this.horse?.gender,
            studbook: this.horse?.studbook,
            horsePony: this.horse?.pony,
            horseWithersHeightCode: this.horse?.withersHeightCode,
            horseUeln: this.horse?.ueln,
            horseFather: this.horse?.father,
            horseMother: this.horse?.mother,
            horseFatherOfMother: this.horse?.fatherOfMother,
            forSale: this.forSale
        } as HorseRegistrationPractitionerRequest
    }

    @Action
    async asRequest(
        data: {
            testRef: string,
            testSetRef: string,
            confirmSaveWithWarnings: boolean,
            startPositions: Array<TargetStartlistPosition>
        }
    ): Promise<RegistrationRequest> {
        return {
            testRef: data.testRef,
            testSetRef: data.testSetRef,
            quantity: this.quantity,
            data: await this.asPractitioner(),
            notes: this.notes,
            startPositions: data.startPositions,
            priceProfileRef: this.priceProfile,
            confirmSaveWithWarnings: data.confirmSaveWithWarnings
        } as RegistrationRequest
    }

    @Action
    async asUpdateRequest(confirmSaveWithWarnings: boolean): Promise<UpdateRegistrationRequest> {
        return {
            data: await this.asPractitioner(),
            outOfCompetition: this.outOfCompetition,
            notes: this.notes,
            priceProfileRef: this.priceProfile,
            confirmSaveWithWarnings: confirmSaveWithWarnings
        }
    }

    get canGetOffer(): boolean {
        if (!this.horse?.name) return false
        if (this.priceProfile?.length == 0) return false
        return true
    }

    @Action
    async asOfferRequest(): Promise<GetHorseOnlyRegistrationOfferRequest> {
        return {
            registrationRef: this.registrationRef,
            practitioner: await this.asPractitioner(),
            priceProfileRef: this.priceProfile,
            quantity: this.quantity,
            outOfCompetition: this.outOfCompetition
        } as GetHorseOnlyRegistrationOfferRequest
    }

    @Action
    async asMetadataRequest(): Promise<GetHorseOnlyFormMetadataRequest> {
        return {
            practitioner: await this.asPractitioner()
        } as GetHorseOnlyFormMetadataRequest
    }
}