
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class EditButton extends Vue {
  @Prop({default: "sm"}) public size?: string;
  @Prop({default: "link-light"}) public variant?: string;

  clicked() {
    this.$emit("click", {})
  }

  get textClass() {
    if (this.variant == 'link-light') {
      return "text-warning"
    } else {
      return ""
    }
  }
}
